import axios from "axios";
export const scheduleMixin = {
  props: ["classId", "academicId", "vacations"],
  data() {
    return {};
  },
  methods: {
    saveSchedule() {
      this.$refs["myForm"].validate();
      if (this.valid) {
        this.loading = true;
        axios
          .post(
            this.getApiUrl +
            "/" +
            "schedules/store/" +
            this.academicId +
            "/" +
            this.classId,
            {
              schedules: this.schedules,
              scheduleLessonsNames: this.scheduleLessonsNames,
              schedulesFromTo: this.schedulesFromTo,
              trash: this.trash,
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
                //the token is a variable which holds the token
              },
            }
          )
          .then((res) => {
            if (res.data.status.error == false) {
              // schedule saved successfully
              this.editMode = false;
              this.trash = [];
              this.getSchedules();
              this.loading = false;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    cancelEdit() {
      this.editMode = false;
      this.trash = [];
      this.getSchedules();
    },
    addColumn() {
      this.editMode = true;
      var count = 0;
      if (this.scheduleLessonsNames.length > 0) {
        if (
          this.scheduleLessonsNames[this.scheduleLessonsNames.length - 1]
            .type == "lesson"
        ) {
          count = parseInt(
            parseInt(
              this.scheduleLessonsNames[this.scheduleLessonsNames.length - 1]
                .name
            ) + 1
          );
        } else if (
          this.scheduleLessonsNames[this.scheduleLessonsNames.length - 2]
            .type == "lesson"
        ) {
          count = parseInt(
            parseInt(
              this.scheduleLessonsNames[this.scheduleLessonsNames.length - 2]
                .name
            ) + 1
          );
        }
      } else {
        count = 1;
      }
      this.scheduleLessonsNames.push({
        type: "lesson",
        name: count,
      });
      this.schedulesFromTo.push({
        from: "",
        to: "",
      });

      this.schedules.forEach((day, index) => {
        this.schedules[index].lessons.push({
          type: "lesson",
          subject: "",
          teacher: "",
          subject_id: "",
          teacher_id: "",
          subjectItems: this.subjects,
          teacherItems: [],
        });
      });
      this.schedulesFromTo[
        this.schedulesFromTo.length - 1
      ].from = this.schedulesFromTo[this.schedulesFromTo.length - 2].to;

      // $(".table-container").addEventListener("mousewheel", function(e) {
      //   e.preventDefault();
      //   $(".child").scrollTop += 3;
      // });
    },
    deleteColumn(index) {
      this.scheduleLessonsNames.splice(index, 1);
      let i = 1;
      this.scheduleLessonsNames.forEach((item, index) => {
        if (this.scheduleLessonsNames[index].type == "lesson") {
          this.scheduleLessonsNames[index].name = i;
          i++;
        }
      });

      for (i = index; i < this.schedulesFromTo.length; i++) {
        // Runs 5 times, with values of step 0 through 4.
        this.schedulesFromTo[i].to = "";
        this.schedulesFromTo[i].from = "";
      }

      this.schedulesFromTo.splice(index, 1);
      this.schedules.forEach((day, arrayIndex) => {
        if (this.schedules[arrayIndex].lessons[index].lesson_id) {
          this.trash.push(this.schedules[arrayIndex].lessons[index].lesson_id);
        }
        this.schedules[arrayIndex].lessons.splice(index, 1);
      });
      if (this.schedulesFromTo.length > index && index != 0) {
        this.schedulesFromTo[index].from = this.schedulesFromTo[index - 1].to;
      }
    },
    getSchedules() {
      var optional = "";
      // if (this.vacations) {
      //   optional = "/vacations";
      // }
      this.loading = true
      axios
      .get(
        this.getApiUrl +
        "/" +
        "schedules/get/" +
        this.academicId +
        "/" +
        this.classId +
        optional,
        {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        }
        )
        .then((res) => {
          if (res.data.status.error == false) {
            this.schedules = res.data.data.schedules;
            this.scheduleLessonsNames = res.data.data.scheduleLessonsNames;
            // console.log(this.scheduleLessonsNames);
            this.schedulesFromTo = res.data.data.schedulesFromTo;
          }
          this.loading = false
        })
        .catch((err) => {
          console.log(err);
        });
      },
    getTeachers(dayIndex, index) {
      var subject_id = this.schedules[dayIndex].lessons[index].subject_id;
      axios
        .get(
          this.getApiUrl +
          "/" +
          "schedules/getTeachersUnderSubject/" + this.academicId + '/' + this.classId + '/' +
          subject_id,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
              //the token is a variable which holds the token
            },
          }
        )
        .then((res) => {
          if (res.data.status.error == false) {
            this.schedules[dayIndex].lessons[index].teacherItems =
              res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    changeColumnType(index) {
      let newType = this.scheduleLessonsNames[index].type;
      this.scheduleLessonsNames[index] = {
        type: newType,
      };
      this.resetLessonNumbers();

      if (Array.isArray(this.schedules)) {
        this.schedules.forEach((row, rowIndex) => {
          Object.assign(this.schedules[rowIndex].lessons[index], {
            type: newType,
            subject: null,
            teacher: null,
            subject_id: "",
            teacher_id: "",
            subjectItems: this.subjects,
          });
        });
      } else if (typeof this.schedules === "object") {
        var _this = this;
        Object.keys(this.schedules).forEach(function (key) {
          Object.assign(_this.schedules[key].lessons[index], {
            type: newType,
            subject: null,
            teacher: null,
            subject_id: "",
            teacher_id: "",
            subjectItems: _this.subjects,
          });
        });
      }
    },
    resetLessonNumbers() {
      let i = 1;
      this.scheduleLessonsNames.forEach((item, index) => {
        if (this.scheduleLessonsNames[index].type == "lesson") {
          this.scheduleLessonsNames[index].name = i;
          i++;
        }
      });
    },
  },
  mounted() {
    if (this.editable) {
      axios
        .get(
          this.getApiUrl + "/schedules/getSubjectsUnderClass/" + this.classId,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.subjects = response.data.data;
        });
    }
  },
  watch: {
    classId() {
      this.getSchedules();
    },
  },
};
